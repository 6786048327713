<template>
  <div class="elements">
    <div class="elements__left elements__left--full">
      <div class="elements__left-info" @click="showChangeFastenerModal">
        <div class="name">
          {{ $t('message.junction.layers.mount.fastener') }}
        </div>
        <div class="material">{{ getFastenerName(selected) }}</div>
      </div>
      <div class="chevron">
        <img class="" src="@/assets/img/icons/filter--select.svg" alt="↓" v-if="isSelectActive" />
        <img class="" src="@/assets/img/icons/filter--select--disabled.svg" alt="↓" v-else />
      </div>
    </div>
    <div class="elements__mount">
      <div class="fastener-info__base">
        <div class="fastener-info__wrapper">
          <i18n
            path="message.junction.layers.mount.base"
            tag="div"
            class="fastener-info__base-text"
          >
            <template #break>
              <br />
            </template>
          </i18n>
          <div class="layer-select" v-if="isInCustom">
            <select name="cat" v-model="computedBasement" class="select">
              <option v-for="bt in baseTypes" :value="bt.id" :key="`bt-${bt.id}`">
                {{ bt.name }}
              </option>
            </select>
          </div>
          <div class="layer-select layer-select--disabled" v-else>
            <div class="value">{{ getBaseTypeName(basement) }}</div>
          </div>
        </div>
        <Single-layer-footer
          :layer-type="item.type"
          :type="currentJunction.type"
          :rate="item.materialRate"
          :tooltip="item.tooltip"
          :isShow="item.type === 'layer' ? item.material.isCalc : false"
          :units="item.type === 'layer' ? item.material.units : null"
          :mark="item.mark"
          :is-in-custom="isInCustom"
          :is-first="isFirst"
          :layer-index="layerIndex"
          :item-index="itemIndex"
        />
      </div>
      <div class="fastener-info__fastener">
        <div class="fastener-info__fastener-height">
          <i18n path="message.junction.layers.mount.height" tag="div" class="text">
            <template #break>
              <br />
            </template>
          </i18n>
          <div
            class="layer--input-with-units"
            :class="{ 'layer--input-with-units--disabled': !isInCustom && !item.height_mutable }"
          >
            <div class="value" v-if="!isInCustom && !item.height_mutable">
              {{ height }}
            </div>
            <input
              type="number"
              min="0"
              max="1000"
              v-else
              step="0.1"
              v-model.number="computedFastenerHeight"
            />
            <span class="units">{{ $t('message.units.mm') }}</span>
          </div>
        </div>
        <div class="fastener-info__fastener-rate">
          <i18n path="message.junction.layers.mount.rate" tag="div" class="text">
            <template #break>
              <br />
            </template>
          </i18n>
          <div
            class="layer--input-with-units"
            :class="{ 'layer--input-with-units--disabled': !isInCustom }"
          >
            <div class="value" v-if="!isInCustom">
              {{ rate }}
            </div>
            <input
              type="number"
              min="0"
              max="1000"
              v-else
              step="0.1"
              v-model.number="computedFastenerRate"
            />
            <span class="units">{{ $t('message.units.pc') }}</span>
          </div>
        </div>
      </div>
    </div>
    <Modal :isModalOpen="isModalOpen" :isShowClose="false" @close="close">
      <template #body>
        <Fastener-selection
          :fastenerId="selected"
          placement="junction"
          :layerIndex="layerIndex"
          :itemIndex="itemIndex"
          :base-type-id="basement"
          :is-in-custom="isInCustom"
          :available-fasteners="availableFasteners"
          @close="close"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { getItemNumber } from '@/utils/customFunctions'
import Modal from '@/components/elements/Modals/Modal'
import FastenerSelection from '@/components/elements/Modals/FastenerSelection'
import SingleLayerFooter from '@/components/smart/Junction/SingleLayerFooter'

export default {
  props: {
    selected: Number,
    basement: Number,
    height: Number,
    rate: Number,
    layerIndex: Number,
    itemIndex: Number,
    isInCustom: Boolean,
    availableFasteners: Array,
    item: Object,
    isFirst: Boolean
  },
  data: () => ({
    isModalOpen: false
  }),
  components: {
    Modal,
    FastenerSelection,
    SingleLayerFooter
  },
  methods: {
    ...mapMutations({
      UPDATE_JUNCTION_MOUNT_LAYER_PARAM: 'UPDATE_JUNCTION_MOUNT_LAYER_PARAM'
    }),
    getFastenerName(id) {
      return this.allFasteners.find(p => p.id === id)?.name || ''
    },
    getBaseTypeName(id) {
      return this.baseTypes.find(p => p.id === id)?.name || ''
    },
    close() {
      this.isModalOpen = false
    },
    currentJunctions() {
      return this.sectorFromState.junctions
    },
    currentJunction() {
      return this.currentJunctions[this.junctionIndex]
    },
    getCurrentNumber(parentIteration, childIteration) {
      return getItemNumber(parentIteration, childIteration, this.parentLayers)
    },
    showChangeFastenerModal() {
      if (this.isInCustom) {
        this.isModalOpen = true
      }
      if (!this.isInCustom && this.availableFasteners.length > 1) {
        this.isModalOpen = true
      }
    }
  },
  computed: {
    ...mapState({
      allFasteners: state => state.fasteners,
      baseTypes: state => state.baseTypes
    }),
    computedFastenerRate: {
      get() {
        return this.rate
      },
      set(value) {
        if (!isNaN(parseFloat(value)) && value >= 0) {
          this.UPDATE_JUNCTION_MOUNT_LAYER_PARAM({
            sectorIndex: this.sectorIndex,
            junctionIndex: this.junctionIndex,
            layerIndex: this.layerIndex,
            itemIndex: this.itemIndex,
            param: 'mountRate',
            value: value
          })
        }
      }
    },
    computedFastenerHeight: {
      get() {
        return this.height
      },
      set(value) {
        if (!isNaN(parseFloat(value)) && value >= 0) {
          this.UPDATE_JUNCTION_MOUNT_LAYER_PARAM({
            sectorIndex: this.sectorIndex,
            junctionIndex: this.junctionIndex,
            layerIndex: this.layerIndex,
            itemIndex: this.itemIndex,
            param: 'height',
            value: value
          })
        }
      }
    },
    computedBasement: {
      get() {
        return this.basement
      },
      set(value) {
        this.UPDATE_JUNCTION_MOUNT_LAYER_PARAM({
          sectorIndex: this.sectorIndex,
          junctionIndex: this.junctionIndex,
          layerIndex: this.layerIndex,
          itemIndex: this.itemIndex,
          param: 'basementType',
          value: value
        })
      }
    },
    isSelectActive() {
      if (this.isInCustom) {
        return true
      } else {
        return this.availableFasteners.length > 1
      }
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    }
  }
}
</script>

<style scoped lang="sass">
.elements__mount
  grid-column: 1 / 4
  padding-top: rem(15)
  box-shadow: inset 0 rem(-1) 0 #ECECEC
  .fastener-info
    &__wrapper
      display: flex
    &__base
      > *
        flex-direction: row
        align-items: center
      display: flex
      flex-direction: row
      justify-content: space-between
      +media((align-items: (320: flex-start, 400: center)))
      +media((flex-direction: (320: column, 400: row)))
      &-text
        @extend .fs-12
        color: #999999
        margin-right: rem(20)
        min-width: rem(80)
      .layer-select
        +media((width: (320: rem(100), 960: rem(140))))
        font-size: rem(12)
        .select
          @extend .fs-12
          color: $light-black
          font-weight: bold
        &:after
          content: ""
          position: absolute
          top: 50%
          transform: translateY(-50%)
          right: rem(12)
          height: rem(10)
          width: rem(10)
          background-image: url(~@/assets/img/select.svg)
          background-position: center
          background-repeat: no-repeat
          pointer-events: none
          background-size: contain
        &--disabled
          &:after
            background-image: url(~@/assets/img/icons/select--disabled.svg)
    &__fastener
      padding: rem(16) 0 rem(9)
      display: flex
      +media((align-items: (320: flex-start, 400: center)))
      justify-content: space-between
      +media((flex-direction: (320: column, 400: row)))
      &-rate
        +media((padding-top: (320: rem(16), 400: 0)))
      &-height
        .text
          min-width: rem(80)
        .layer--input-with-units
          +media((width: (320: rem(100), 960: rem(140))))
      &-height, &-rate
        display: flex
        flex-direction: row
        align-items: center
        .text
          @extend .fs-12
          color: #999999
          margin-right: rem(20)
</style>
