<template>
  <section class="single-system__layers">
    <article class="single-layer" v-for="(layer, i) in parentLayers" :key="`layer-${i}`">
      <header class="single-layer__header">
        <h3 class="single-layer__header-name">
          <span class="text">{{ layer.name }}</span>
        </h3>
      </header>
      <div class="single-layer__body">
        <div
          v-for="(item, n) in layer.items"
          :key="`child-layer-${i}-number-${n}-computed-${getCurrentNumber(i, n)}`"
          class="single-layer__body-item"
        >
          <junction-layer-item
              v-if="item.type === 'layer'"
              :isComplex="isComplexJunction"
              :layerIndex="i"
              :layerItem="item"
              :layerItemIndex="n"
              @replaceMaterial="choseNewMaterial($event, layer.name)"
          ></junction-layer-item>
          <Mount-layer
            v-else-if="item.type === 'mount'"
            :is-in-custom="false"
            :layer-index="i"
            :item-index="n"
            :item="item"
            :availableFasteners="item.options"
            :selected="item.selected"
            :height="item.height"
            :basement="item.basementType"
            :rate="item.mountRate"
            :is-first="getCurrentNumber(i, n) === 1"
          />
          <Single-layer-footer
            v-if="item.type === 'layer' && !isComplexJunction"
            :layer-type="item.type"
            :type="currentJunction.type"
            :rate="item.materialRate"
            :tooltip="item.tooltip"
            :isShow="item.type === 'layer' ? item.material.isCalc : false"
            :units="item.type === 'layer' ? item.material.units : null"
            :mark="item.mark"
            :is-in-custom="false"
            :is-first="getCurrentNumber(i, n) === 1"
          />
        </div>
      </div>
    </article>
    <Modal
      :isModalOpen="isModalOpen"
      :isCloseInHeader="true"
      :isNoRadius="true"
      :isShowClose="true"
      @close="close"
    >
      <template #body>
        <LayerProductsView
          :path="layersPath"
          :layerIndex="layerIndexToSelect"
          :itemIndex="itemIndexToSelect"
          :placement="'junction'"
          :response="responseItems"
          @close="close"
        />
      </template>
    </Modal>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { getItemNumber } from '@/utils/customFunctions'
import Modal from '@/components/elements/Modals/Modal'
import LayerProductsView from '@/components/smart/layersProducts/ProductSelection'
import MountLayer from '@/components/smart/Junction/MountLayer'
import SingleLayerFooter from '@/components/smart/Junction/SingleLayerFooter'
import { getMaterialsForLayer } from '@/api'
import JunctionLayerItem from '@/components/elements/Junction/JunctionLayer.vue'
import { bundleTypes } from '@/utils/bundleTypes'

export default {
  data: () => ({
    isModalOpen: false,
    layersPath: [],
    layerIndexToSelect: '',
    itemIndexToSelect: '',
    isLoading: false,
    responseItems: {}
  }),
  components: {
    JunctionLayerItem,
    Modal,
    LayerProductsView,
    MountLayer,
    SingleLayerFooter
  },
  methods: {
    ...mapMutations({
      UPDATE_JUNCTION_LAYER_ITEM_VALUE: 'UPDATE_JUNCTION_LAYER_ITEM_VALUE'
    }),
    close() {
      this.isModalOpen = false
    },
    getJunctionType() {
      if (this.isComplexJunction) {
        return bundleTypes.complex
      }

      return this.isCustomJunction ? bundleTypes.alter : bundleTypes.default
    },
    choseNewMaterial(evt, layerName) {
      const { layerItemName, layerIndex, itemIndex } = evt
      this.isLoading = true

      getMaterialsForLayer(
        this.$i18n.locale,
        this.getJunctionType(),
        layerIndex,
        itemIndex,
        'stock',
        this.currentJunction.id
      ).then(response => {
        this.isLoading = false
        this.isModalOpen = !this.isModalOpen
        this.layerIndexToSelect = layerIndex
        this.itemIndexToSelect = itemIndex
        this.responseItems = response.data
        this.layersPath = [layerName, layerItemName]
      })
    },
    getCurrentNumber(parentIteration, childIteration) {
      return getItemNumber(parentIteration, childIteration, this.parentLayers)
    }
  },
  computed: {
    ...mapState({
      allFasteners: state => state.fasteners,
      layersProducts: state => state.layersProducts
    }),
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    },
    currentJunctions() {
      return this.sectorFromState.junctions
    },
    currentJunction() {
      return this.currentJunctions[this.junctionIndex]
    },
    parentLayers() {
      return this.currentJunction.layers
    },
    isCustomJunction() {
      return this.currentJunction?.variables !== null
    },
    isComplexJunction() {
      return this.currentJunction?.isComplex
    }
  }
}
</script>

<style lang="sass">
.single-junction
  .single-layer__body-item
    .btn-handle
      grid-row: 1 / 2
</style>
