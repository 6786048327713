<template>
  <div class="uploader">
    <button @click="deleteFile" class="uploader__delete" v-if="currentJunction.drawingUrl">
      <svg
        width="14"
        height="20"
        viewBox="0 0 14 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.00001 15L5 11M8.99988 15L9 11M0 4.49998H14M1 6.99998H13L12.574 17.0769C12.5327 18.1507 11.6501
                 19 10.5754 19H3.42456C2.34989 19 1.46734 18.1507 1.42603 17.0769L1 6.99998ZM5 1.49998H9L10 4.49998H4L5
                 1.49998Z"
          stroke="#999999"
          stroke-width="1.5"
        ></path>
      </svg>
    </button>
    <div
      class="uploader__loaded-img dragscroll"
      :style="{ overflow: isDesktop ? 'hidden' : 'auto' }"
      ref="loadedImgWrapper"
      :class="{ grabbing: imageZoom > 1 }"
    >
      <img
        :src="currentJunction.drawingUrl"
        alt=""
        v-if="currentJunction.drawingUrl"
        class="uploader__loaded-img--picture"
        :style="{ transform: `scale(${imageZoom})` }"
        ref="loadedImage"
      />
    </div>
    <img
      src="@/assets/img/icons/uploader--pic.svg"
      alt="upload"
      class="uploader__pic"
      v-if="currentJunction.isCustom && currentJunction.drawingUrl === null && !isUploadStart"
    />
    <loading
      :active.sync="isPreloaderShow"
      :can-cancel="false"
      color="#E30713"
      :height="176"
      :width="80"
      :opacity="1"
      backgroundColor="transparent"
      :z-index="3"
      :is-full-page="false"
    ></loading>
    <ul class="uploader__errors" v-if="errors.length > 0">
      <li class="uploader__errors-item" v-for="error in errors" :key="error">
        {{ error }}
      </li>
    </ul>
    <label
      for="myfile"
      class="uploader__btn"
      v-if="currentJunction.isCustom && currentJunction.drawingUrl === null && !isUploadStart"
    >
      <input
        type="file"
        class="uploader__btn-input"
        id="myfile"
        name="userFile"
        @change="addFile($event)"
        accept="image/*"
      />
      <span class="icon">
        <img src="@/assets/img/icons/uploader--add.svg" alt="add" />
      </span>
      {{ $t('message.uploader.add') }}
    </label>
    <Image-controls
      :imageZoom="imageZoom"
      v-show="currentJunction.drawingUrl"
      @increaseZoom="increaseZoom"
      @decreaseZoom="decreaseZoom"
      @updateZoom="updateZoom"
      @showLightBox="isLightBoxShow = true"
    />
    <ImageLightBox @close="close" :isShow="isLightBoxShow" :url="currentJunction.drawingUrl" />
  </div>
</template>

<script>
import { scrollWithZoom } from '@/utils/customFunctions'
import ImageLightBox from '@/components/smart/uploader/ImageLightBox'
import ImageControls from '@/components/smart/uploader/imageControls'
import dragscroll from 'dragscroll/dragscroll.js'
import device from 'current-device'
import { mapMutations, mapActions } from 'vuex'
import { uploadDrawing } from '@/api'

export default {
  data: () => ({
    imageZoom: 1,
    picture: null,
    errors: [],
    isPreloaderShow: false,
    isUploadStart: false,
    imageNaturalHeight: null,
    imageNaturalWidth: null,
    isLightBoxShow: false,
    isDesktop: ''
  }),
  components: {
    ImageLightBox,
    ImageControls
  },
  watch: {
    imageZoom() {
      if (this.imageZoom > 0) {
        scrollWithZoom(this.$refs.loadedImgWrapper, this.imageZoom)
      }
    }
  },
  methods: {
    ...mapMutations({
      UPDATE_JUNCTION_PARAM: 'UPDATE_JUNCTION_PARAM'
    }),
    ...mapActions({
      deleteFiles: 'deleteFiles'
    }),
    addFile(e) {
      const regex = new RegExp('([a-zA-Zа-яА-Я0-9\\s_\\\\.\\-():])+(.jpg|.png|.jpeg|.JPG|.PNG)$')
      if (e.target.files.length > 0) {
        const isNameValid = regex.test(e.target.files[0].name)
        const fileSize = parseFloat((e.target.files[0].size / (1024 * 1024)).toFixed(2))
        let isFileSizeValid = false
        let isFileValid = false
        this.errors = []
        if (fileSize >= 1.5) {
          this.errors.push('Файл должен быть меньше чем 1.5 мб')
          isFileSizeValid = false
        } else {
          isFileSizeValid = true
        }
        if (
          isNameValid &&
          (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpeg')
        ) {
          isFileValid = true
        } else {
          this.errors.push(this.$t('message.uploader.errors.format'))
          isFileValid = false
        }
        if (isFileSizeValid && isFileValid) {
          this.upload(e.target.files[0])
        }
      }
    },

    upload(file) {
      this.picture = null
      this.isPreloaderShow = true
      this.isUploadStart = true
      uploadDrawing(file, this.$i18n.locale).then(url => {
        this.isPreloaderShow = false
        // console.log(url);

        this.UPDATE_JUNCTION_PARAM({
          index: this.sectorIndex,
          junctionIndex: this.junctionIndex,
          param: 'drawingUrl',
          value: url.data
        })
      })
    },
    deleteFile() {
      // const baseUrl = (process.env.NODE_ENV === 'development') ? process.env.VUE_APP_API_URL : `${location.origin}/action/`;
      // const formData = new FormData();
      let files = []
      files.push(this.currentJunction.drawingUrl)
      this.deleteFiles({
        files: JSON.stringify(files),
        sectorIndex: this.sectorIndex,
        junctionIndex: this.junctionIndex
      }, this.$i18n.locale)
      this.isUploadStart = false
      this.imageZoom = 1
      dragscroll.reset()
    },
    updateZoom(value) {
      this.imageZoom = value
    },
    increaseZoom() {
      this.imageZoom += 1
    },
    decreaseZoom() {
      this.imageZoom -= 1
    },
    showLightBox() {
      this.isLightBoxShow = !this.isLightBoxShow
    },
    close() {
      this.isLightBoxShow = false
    }
  },
  mounted() {
    dragscroll.reset()
    device.desktop() ? (this.isDesktop = true) : (this.isDesktop = false)
  },
  computed: {
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    currentJunctions() {
      return this.sectorFromState.junctions
    },
    currentJunction() {
      return this.currentJunctions[this.junctionIndex]
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    }
  }
}
</script>

<style scoped lang="sass">
.uploader
  display: flex
  overflow: hidden
  flex-direction: column
  justify-content: center
  align-items: center
  background: $default
  box-shadow: inset 0 rem(-1) 0 #ECECEC
  .vld-overlay
    position: relative
    line-height: 0
  // min-height: rem(176)
  &__loaded-img
    overflow: hidden
    line-height: 0
    &.grabbing
      cursor: grab
    &--picture
      line-height: 0
      max-width: 100%
      transform: scale(1)
      transform-origin: 0 0
  &__pic
    padding: rem(28) 0 rem(19)
  &__errors
    display: flex
    flex-direction: column
    padding: 0 rem(20) 0 rem(40)
    margin: 0
    &-item
      @extend .fs-12
      color: $red
      &:last-child
        padding-bottom: rem(10)
  &__btn
    @extend .fs-12
    margin-bottom: rem(16)
    cursor: pointer
    display: flex
    flex-direction: row
    align-items: center
    padding: rem(9) rem(14)
    border: rem(1) dashed $dark-grey
    box-sizing: border-box
    border-radius: rem(4)
    min-width: rem(188)
    transition: .5s
    &:hover
      border-color: $green
    .icon
      margin-right: rem(17)
    &-input
      display: none
      pointer-events: none
      opacity: 0
      position: absolute
      font-size: 0
  &__nav
    width: 100%
    flex-direction: row
    justify-content: center
    align-items: center
    background: #ECECEC
    display: grid
    z-index: 1
    grid-template-columns: rem(40) 5fr rem(40) rem(40)
    grid-column-gap: rem(1)
    box-shadow: 0 rem(1) rem(5) rgba(51, 51, 51, 0.5)
    &-range
      background: #ffffff
      width: 100%
      height: rem(40)
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center
    &-btn
      cursor: pointer
      height: rem(40)
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      border: none
      box-shadow: none
      outline: none
      transition: .5s
      background: #fff
      &:disabled
        opacity: .5
  &__delete
    position: absolute
    z-index: 1
    @extend .clear-btn
    background: #fff
    height: rem(40)
    width: rem(40)
    top: 0
    right: 0
    svg
      path
        transition: .5s
    &:hover
      background: $red-hover
      svg
        path
          stroke: #fff
.sidebar--mobile
  .uploader
    &__delete
      top: rem(40)
</style>
